import { Link } from "gatsby"
import React from "react"
import Image from "../Image"
import "./styles.scss"

const PageCards = ({ cards }) => {
  const cardItem = ({ label, description, image, to }) => (
    <Link key={label} to={to} className="page-cards__card">
      <div className="page-cards__card-grid">
        <div>
          <div className="page-cards__card-label">{label}</div>
          <div className="page-cards__card-description">{description}</div>
        </div>
        <div className="page-cards__card-image-container">
          {
            <Image
              imgStyle={{
                objectFit: "contain",
                width: "auto",
                left: "auto",
                right: 0,
              }}
              className="page-cards__card-image"
              filename={image}
            />
          }
        </div>
      </div>
    </Link>
  )
  return <div className="page-cards">{cards.map(card => cardItem(card))}</div>
}

export default PageCards
